.img-container {
  height: 300px;
  width: 80%;
  position: relative;
}

@media (max-width: 700px) {
  .img-container {
    width: 100%;
    height: 250px;
  }
}
