.heading {
  font-family: var(--font);
  font-size: 2rem;
}

.links {
  text-decoration: none;
  color: #333;
}

.item {
  margin-top: 30px;
}

.logout {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.list-item {
  font-family: var(--font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

@media (max-width: 700px) {
  .logout {
    top: 75%;
  }
}
