@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.header {
  background-color: #890008;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: var(--font);
  font-style: italic;
  position: relative;
}

.login-heading {
  padding: 4.5rem;
  font-size: 2.5rem;
  margin: 0;
}

.logo-circle {
  height: 100px;
  width: 100px;
  background: url("../../Assets/logo.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -3rem;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  padding: 8rem;
  width: 50%;
}

.login-text {
  font-family: var(--font);
  font-size: 2rem;
  padding: 1.2rem 0;
}

.field {
  width: 100%;
  display: block;
  padding: 1.2rem 0;
  font-size: 0.8rem;
}

.field::placeholder {
  font-family: var(--font);
  font-size: 1.1rem;
}

.field:focus {
  outline: #fff;
  border-bottom: 2px #000 solid;
}

.forgot {
  font-family: var(--font);
  font-size: 0.9rem;
  color: #890008;
  text-align: right;
  margin-top: 10px;
  font-weight: 500;
}

.login-submit {
  background-color: #890008;
  color: #fff;
  font-family: var(--font);
  padding: 1rem;
  margin: 2rem auto;
  display: block;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}

@media (max-width: 1300px) {
  .login-box {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .login-box {
    width: 70%;
  }
}
@media (max-width: 800px) {
  .login-box {
    width: 90%;
    padding: 2rem;
  }
  .login-heading {
    padding: 4rem 1rem;
  }
}

@media (max-width: 700px) {
  .login-heading {
    padding: 9rem 1rem;
  }
}
