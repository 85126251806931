.img-container {
  height: 300px;
  width: 80%;
  position: relative;
}

.upload-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

@media (max-width: 700px) {
  .img-container {
    width: 100%;
    height: 250px;
  }
}
